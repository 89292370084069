/* eslint-disable no-undef */
import {
  AccessTime,
  AccountCircle,
  Cancel,
  CancelOutlined,
  Check,
  Close,
  ContactMail,
  ShoppingCartCheckout,
  Warning,
} from "@mui/icons-material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {
  Alert,
  Box,
  Card,
  CircularProgress,
  Grid,
  Link,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  Typography,
} from "@mui/joy";
import DineroFactory from "dinero.js";
import { DateTime } from "luxon";
import CancelOrderModal from "pages/Dash/Components/CancelOrderModal/CancelOrderModal";
import PapertrailList from "pages/Dash/Components/PapertailList/PapertrailList";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  useApproveOrderMutation,
  useDenyOrderMutation,
  useGetConfigQuery,
  useGetOrdersQuery,
  useGetPapertrailQuery,
} from "services/osare";

export default function DashOrderSingle() {
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const { data: configData, isLoading: isConfigLoading } = useGetConfigQuery();

  console.log("state: ", state);
  // const [currentOrderStatus, setCurrentOrderStatus] = useState(0);
  // const [isOrderStatusSelectorOpen, setIsOrderStatusSelectorOpen] =
  //   useState(false);
  const [isCancellationModalOpen, setIsCancellationModalOpen] = useState(false);
  const {
    data: orderData,
    refetch: refetchOrderData,
    isLoading: isOrderLoading,
    isFetching: isOrderFetching,
  } = useGetOrdersQuery(
    {
      id: searchParams.get("id"),
      includePayments: true,
      resolveUser: true,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );
  // eslint-disable-next-line no-unused-vars
  const [isActioningOrder, setIsActioningOrder] = useState(false);
  const [
    approveOrder,
    { data: approveOrderData, isLoading: isApprovingOrder },
  ] = useApproveOrderMutation();
  const [denyOrder, { data: denyOrderData, isLoading: isDenyOrderLoading }] =
    useDenyOrderMutation();
  const { data: papertrailData, refetch: refetchPapertrail } =
    useGetPapertrailQuery({
      orderId: searchParams.get("id"),
    });

  const mapRoomDatatoRoomId = (roomId) => {
    const roomData = orderData[0].listingData.roomData.find(
      (r) => roomId === r._id
    );

    return roomData;
  };

  const mapAccommodationDatatoAccommodationId = (accommodationId) => {
    const accommodationData = orderData[0].listingData.accommodationData.find(
      (a) => accommodationId === a._id
    );

    return accommodationData;
  };

  // eslint-disable-next-line no-unused-vars
  const chosenInventory = () => {
    const inventoryData = orderData[0].listingData.availabilityData[
      orderData[0].availabilityId
    ].inventory.find((i) => i._id === orderData[0].inventoryId);

    return inventoryData;
  };

  const chosenAvailability = () => {
    const availabilityData = orderData[0].listingData.availabilityData.find(
      (a) => a._id === orderData[0].availabilityId
    );

    return availabilityData;
  };

  useEffect(() => {
    refetchPapertrail();
  }, []);

  useEffect(() => {
    setIsActioningOrder(false);
    if (isDenyOrderLoading || isApprovingOrder) {
      refetchOrderData();
    }
  }, [isApprovingOrder, isDenyOrderLoading, approveOrderData, denyOrderData]);

  const ScheduledPaymentItem = (props) => {
    return (
      <Card variant={"outlined"}>
        {props.isAwaitingApproval ? (
          <Alert
            startDecorator={<Warning />}
            style={{ marginBottom: 10 }}
            color="warning"
          >
            This scheduled payment is on hold until this order is approved.
          </Alert>
        ) : null}
        <Typography>
          {props.purpose === "platformAndDepositFee"
            ? "Platform & Deposit"
            : "Booking"}{" "}
          Charge
        </Typography>

        <Typography fontFamily={"monospace"} fontSize={32}>
          {DineroFactory({
            amount: parseInt(props.chargeAmount) * 100,
            currency: orderData[0].listingData.merchantAccount.defaultCurrency,
          }).toFormat("$0,0")}
        </Typography>

        {props.status === "pending" && (
          <Typography display={"flex"}>
            <FiberManualRecordIcon color={"warning"} sx={{ marginRight: 1 }} />{" "}
            Due to be charged on{" "}
            {DateTime.fromMillis(props.onOrAfterTimestamp).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}
          </Typography>
        )}

        {props.status === "cancelled" && (
          <Typography display={"flex"}>
            <FiberManualRecordIcon color={"error"} sx={{ marginRight: 1 }} />{" "}
            Cancelled on{" "}
            {DateTime.fromMillis(props.onOrAfterTimestamp).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}{" "}
          </Typography>
        )}

        {props.status === "refunded" && (
          <Typography display={"flex"}>
            <FiberManualRecordIcon color={"error"} sx={{ marginRight: 1 }} />{" "}
            Refunded on{" "}
            {DateTime.fromMillis(props.updatedAt).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}
          </Typography>
        )}

        {props.status === "succeeded" && (
          <Typography display={"flex"}>
            <FiberManualRecordIcon color={"success"} sx={{ marginRight: 1 }} />{" "}
            Succeeded on{" "}
            {DateTime.fromMillis(props.onOrAfterTimestamp).toLocaleString(
              DateTime.DATE_MED_WITH_WEEKDAY
            )}
          </Typography>
        )}
      </Card>
    );
  };

  const OrderSummary = () => {
    return (
      <Card variant="soft" color={"primary"}>
        <Typography level="title-lg">
          <Link
            target="_blank"
            href={`${configData.sites.bookings.url}/listing?listingId=${orderData[0].listingData._id}`}
          >
            {orderData[0].listingData.name}
          </Link>
        </Typography>
        <Typography gutterBottom level="title-md">
          {orderData[0].listingData.tagline}
        </Typography>

        <Typography startDecorator={<AccessTime />}>
          Selected booking date:{" "}
          {DateTime.fromMillis(
            chosenAvailability().fromTimestamp
          ).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}{" "}
          -{" "}
          {DateTime.fromMillis(chosenAvailability().toTimestamp).toLocaleString(
            DateTime.DATE_MED_WITH_WEEKDAY
          )}
        </Typography>
        <Typography startDecorator={<ShoppingCartCheckout />}>
          Booked on:{" "}
          {orderData[0].createdAt
            ? DateTime.fromMillis(orderData[0].createdAt).toLocaleString(
                DateTime.DATE_MED_WITH_WEEKDAY
              )
            : "(no date)"}{" "}
          (around {DateTime.fromMillis(orderData[0].createdAt).toRelative()})
        </Typography>
        {orderData[0].customerData ? (
          <>
            <Typography startDecorator={<AccountCircle />}>
              {orderData[0].customerData.name}
            </Typography>
            <Typography startDecorator={<ContactMail />}>
              {orderData[0].customerData.email}{" "}
              {orderData[0].customerData.phone
                ? ` / ${orderData[0].customerData.phone}`
                : ""}
            </Typography>
          </>
        ) : (
          <Typography color="warning" startDecorator={<AccountCircle />}>
            Customer not found!
          </Typography>
        )}
      </Card>
    );
  };

  const ApproveOrderAlert = () => {
    return (
      <Alert
        onClick={() => {
          if (orderData[0].isAwaitingApproval) {
            if (confirm("Are you sure you want to approve this order?")) {
              approveOrder({ orderId: orderData[0]._id });
              setIsActioningOrder(true);
            }
          } else {
            alert("This order has already been approved.");
          }
        }}
        style={{ cursor: "pointer" }}
        variant="soft"
        color={orderData[0].isAwaitingApproval ? "warning" : "success"}
      >
        <div>
          <Typography startDecorator={<Check />} gutterBottom>
            {orderData[0].isAwaitingApproval
              ? "Approve this order"
              : "This order is confirmed!"}
          </Typography>
          <Typography gutterBottom>
            {orderData[0].isAwaitingApproval
              ? "Tap to confirm this order. Until then, processing payments for this order are on hold. "
              : "Payments for this order are now active and are being processed according to this order."}
          </Typography>
        </div>
      </Alert>
    );
  };

  const DenyOrderAlert = () => {
    return (
      <Alert
        onClick={() => {
          if (confirm("Are you sure you want to deny this order?")) {
            denyOrder({ orderId: orderData[0]._id });
            setIsActioningOrder(true);
          }
        }}
        style={{ cursor: "pointer" }}
        color={"warning"}
      >
        <div>
          <Typography startDecorator={<Close />} gutterBottom>
            Deny this order
          </Typography>
          <Typography>
            Tap to deny this order. Denying this order will cancel all payments
            and will not allow the customer to book this order. The inventory
            will be returned from the customer order.
          </Typography>
        </div>
      </Alert>
    );
  };

  const CancelActiveOrderAlert = () => {
    return (
      <Alert
        sx={{ cursor: "pointer" }}
        startDecorator={<Cancel />}
        color="danger"
        variant="soft"
        onClick={() => setIsCancellationModalOpen(true)}
      >
        <div>
          <Typography level="title-md">Cancel this order?</Typography>
        </div>
      </Alert>
    );
  };

  const OrderCancelledAlert = () => {
    return (
      <Alert color={"danger"} variant="soft">
        <div>
          <Typography
            gutterBottom
            level="title-md"
            startDecorator={<CancelOutlined />}
          >
            Order cancelled{" "}
            {orderData[0].isCancelledByMerchant ? "by merchant" : "by customer"}
          </Typography>
          <Typography level="title-sm">
            Pending scheduled payments were cancelled and inventory was returned
            to this listing.
          </Typography>
        </div>
      </Alert>
    );
  };

  const OrderDetails = () => {
    return (
      <Card>
        <Typography level="title-lg">Order details</Typography>
        <Tabs
          aria-label="Basic tabs"
          disableIndicator
          variant="outlined"
          defaultValue={0}
          sx={{
            borderRadius: "md",
            boxShadow: "sm",
            overflow: "auto",
          }}
        >
          <TabList disableUnderline>
            <Tab disableIndicator>Event</Tab>
            <Tab disableIndicator>Accommodation</Tab>
            <Tab disableIndicator>Customer</Tab>
            <Tab disableIndicator>Card Payer</Tab>
          </TabList>
          <TabPanel value={0}>
            <Typography>
              {orderData[0].listingData.eventData[0].name}
            </Typography>
            <Typography>
              {orderData[0].listingData.eventData[0].tagline}
            </Typography>
          </TabPanel>

          <TabPanel value={1}>
            <Typography>
              Accommodation:{" "}
              {
                mapAccommodationDatatoAccommodationId(
                  orderData[0].accommodationId
                ).name
              }
            </Typography>
            <Typography>
              Room Quantity: {orderData[0].inventoryQuantity}
            </Typography>
            <Typography>
              Room Type: {mapRoomDatatoRoomId(orderData[0].roomId).name}
            </Typography>
          </TabPanel>

          <TabPanel value={2}>
            {orderData[0].user ? (
              <>
                <Alert>
                  These are the details of the person who was logged in when
                  making this order
                </Alert>
                <Typography>{orderData[0].user.displayName}</Typography>
                <Typography>{orderData[0].user.email}</Typography>
              </>
            ) : (
              <Typography>Customer not found!</Typography>
            )}
          </TabPanel>

          <TabPanel value={3}>
            <Alert>
              These are the details of the person who paid for this order
            </Alert>
            {orderData[0].customerData ? (
              <>
                <Typography>{orderData[0].customerData.name}</Typography>
                <Typography>{orderData[0].customerData.email}</Typography>
                {orderData[0].customerData?.address?.line1 && (
                  <Typography>
                    {orderData[0].customerData.address.line1}
                  </Typography>
                )}
                {orderData[0].customerData?.address?.city && (
                  <Typography>
                    {orderData[0].customerData.address.city}
                  </Typography>
                )}
                <Typography>
                  {orderData[0].customerData.address.postal_code}
                </Typography>
              </>
            ) : (
              <Typography>Customer not found!</Typography>
            )}
          </TabPanel>
        </Tabs>
      </Card>
    );
  };

  const ScheduledPaymentsCard = () => {
    return (
      <Card variant="outlined">
        <Typography gutterBottom level="title-lg">
          Payments
        </Typography>
        <Grid container spacing={2}>
          {orderData[0].scheduledPayments.map((paymentItem) => (
            <Grid xs={12} key={`grid-` + paymentItem._id}>
              <ScheduledPaymentItem key={paymentItem._id} {...paymentItem} />
            </Grid>
          ))}
        </Grid>
      </Card>
    );
  };

  return (
    <Grid container spacing={2}>
      <Grid xs={0} md={3}></Grid>
      <Grid xs={12} md={6} marginTop={2}>
        <CancelOrderModal
          open={isCancellationModalOpen}
          closeFn={setIsCancellationModalOpen}
          refetchOrderDataFn={refetchOrderData}
          {...state}
        />
        {isOrderLoading ||
        isOrderFetching ||
        isDenyOrderLoading ||
        isConfigLoading ? (
          <Box display={"flex"}>
            <Typography
              textAlign={"center"}
              startDecorator={<CircularProgress />}
            >
              Loading order...
            </Typography>
          </Box>
        ) : (
          <>
            <OrderSummary />

            <Box margin={2} />
            {!orderData[0].isCancelled ? <ApproveOrderAlert /> : null}
            <Box margin={2} />
            {orderData[0].isAwaitingApproval ? <DenyOrderAlert /> : null}
            {!orderData[0].isAwaitingApproval && orderData[0].isCancelled ? (
              <OrderCancelledAlert />
            ) : null}
            {!orderData[0].isCancelled && !orderData[0].isAwaitingApproval && (
              <CancelActiveOrderAlert />
            )}

            <Box margin={2} />
            {/* <Grid xs={12}>
              <OrderStatusChip
                orderData={state}
                papertrailData={papertrailData}
              />
            </Grid> */}
            {/* <Box margin={2} /> */}
            {/* <Grid xs={12}>
              <OrderStatusSelector
                open={isOrderStatusSelectorOpen}
                setOpen={setIsOrderStatusSelectorOpen}
                nextOrderStatus={currentOrderStatus}
                orderData={state}
                refetchPapertrail={refetchPapertrail}
              />
              <Select
                fullWidth
                labelId="order-status-change"
                value={currentOrderStatus}
                label="Order status"
                onChange={(e) => {
                  setCurrentOrderStatus(e.target.value);
                  setIsOrderStatusSelectorOpen(true);
                  console.log(e.target);
                }}
              >
                <Option selected value={0} disabled>
                  Choose a new status...
                </Option>
                <Option value={1}>Pending</Option>
                <Option value={2}>On hold</Option>
                <Option value={3}>In progress</Option>
                <Option value={4}>Ready</Option>
              </Select>
            </Grid> */}
            <Box margin={2} />
            <Grid xs={12}>
              <OrderDetails />
            </Grid>
            <Box margin={2} />
            <Grid xs={12}>
              <ScheduledPaymentsCard />
            </Grid>
            <Box margin={2} />
            <Grid xs={12}>
              <Card>
                <Typography gutterBottom level="title-lg">
                  Papertrails
                </Typography>
                <PapertrailList
                  refetchPapertrail={refetchPapertrail}
                  orderData={state}
                  papertrailData={papertrailData}
                />
              </Card>
            </Grid>
          </>
        )}
      </Grid>
      <Grid xs={0} md={3}></Grid>
    </Grid>
  );
}
